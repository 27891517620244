import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../layouts/Main.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/pages/OrderInvoice.vue"),
      },
      {
        path: "/OrderInvoice",
        name: "OrderInvoice",
        component: () => import("../views/pages/OrderInvoice.vue"),
      },
      {
        path: "/OrderLine",
        name: "OrderLine",
        component: () => import("../views/pages/OrderLine.vue"),
      },
      {
        path: "/OrderLineRound",
        name: "OrderLineRound",
        component: () => import("../views/pages/OrderLineRound.vue"),
      },
      // {
      //   path: "/MenuShow",
      //   name: "MenuShow",
      //   component: () => import("../views/pages/MenuShow.vue"),
      // },
    ],
  },
  {
    path: "/Login",
    component: () => import("../layouts/Auth.vue"),
  },
];

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  publicPathmode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE,
  routes,
});

export default router;
